
<div class="login-container" >
  <maced-global-card *ngIf="!globalSystemErrorPage" cardId="login" cardName="loginBlock">
    <div class="login-block">
      <div class="login-block__card" id="prelogin">
        <h1 class="login-block__card-title">{{data && data.languageJson && data.languageJson.login && data.languageJson.login.account_welcome_to_text}} <br> {{data && data.languageJson && data.languageJson.login && data.languageJson.login.account_lms_text}}</h1>
        <maced-button *ngIf="!isPlatformBrowser" buttonId="Primary1" label="{{data && data.languageJson && data.languageJson.login && data.languageJson.login.login_btn}}"
        ariaLabel="{{data && data.languageJson && data.languageJson.login && data.languageJson.login.login_btn}}"
          color="primary" type="link" url='{{curl}}' [queryParams]="{id:'login'}" target='_self' class="large-button" size="large">
        </maced-button>
        <maced-button *ngIf="isPlatformBrowser" buttonId="Primary2" label="{{data && data.languageJson && data.languageJson.login && data.languageJson.login.login_btn}}"
        ariaLabel="{{data && data.languageJson && data.languageJson.login && data.languageJson.login.login_btn}}"
          color="primary" (click)='getMachineIdentifierFromServer(loginbuttonText)' class="large-button" size="large">
        </maced-button>
        <p class="login-block__card-details">
          <span class="account-instruction">{{data && data.languageJson && data.languageJson.login && data.languageJson.login.account_create_msg}}</span>
        </p>
        <maced-button *ngIf="!isPlatformBrowser" buttonId="Default11" label="{{data && data.languageJson && data.languageJson.login && data.languageJson.login.account_create_btn}}"
        ariaLabel="{{data && data.languageJson && data.languageJson.login && data.languageJson.login.account_create_btn}}" type="link" url='{{currentUrl}}?id=createaccount'
          target='_self' class="large-button" color="secondary" size="large">
        </maced-button>
        <maced-button *ngIf="isPlatformBrowser" buttonId="Default12" label="{{data && data.languageJson && data.languageJson.login && data.languageJson.login.account_create_btn}}"
        ariaLabel="{{data && data.languageJson && data.languageJson.login && data.languageJson.login.account_create_btn}}" (click)='getMachineIdentifierFromServer(createAccountbuttonText)'
          class="large-button" color="secondary" size="large">
        </maced-button>
        <p *ngIf = "!showhelpUrl" class="login-block__card-help-text"><a href = {{helpUrl}} class = "helpUrl" target={{helpUrlTarget}} (click)="trackHelpLinkClick()">{{data && data.languageJson && data.languageJson.login && data.languageJson.login.header_link.help_link}} <span class="sr-only" id="helpLink">{{data.languageJson?.login?.header_link?.help_arialabel}}</span></a></p>
        <p *ngIf = "showhelpUrl" class="login-block__card-help-text"><a class = "helpUrls" >{{data && data.languageJson && data.languageJson.login && data.languageJson.login.header_link.help_link}} <span class="sr-only" id="helpLink">{{data.languageJson?.login?.header_link?.help_arialabel}}</span></a></p>
      </div>
    </div>
  </maced-global-card>
  <div  *ngIf="globalSystemErrorPage" class="u-w-100 page-block-section">
    <app-global-system-error (resetPageLoad)="resetPageLoad()"></app-global-system-error>
  </div>
</div>