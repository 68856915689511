import { HttpClient, HttpParams } from '@angular/common/http';
import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {SsrCookieService} from 'ngx-cookie-service-ssr';
import { GlobalSettings } from 'src/app/global.settings';
import { Data } from '../models/data';
import { ACEToken, MEEToken } from '../models/token';
import { ApiConstants } from 'src/app/core/constant/api.constant';
import { environment } from 'src/environments/environment';
import { LoggerService } from './logger.service';
import { catchError, Observable, of, tap } from 'rxjs';
import { Router } from '@angular/router';
import { cookieCharLimit } from '../models/enum';

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  private baseUrl: string = ApiConstants.aceApiBaseUrl;

  constructor(  @Inject(PLATFORM_ID) private platformId: object ,private router: Router,public data: Data,private http:HttpClient,private cookieService: SsrCookieService, private loggerService: LoggerService) { }
  setRedisData(data:any){
    let url: string = this.baseUrl + ApiConstants.setRedis;
    let sessionUrl = '/api/isSession';
    let cacheValidity = parseInt(data.expires_in) / 60 ;
    if (!this.cookieService.get('token_key') || this.cookieService.get('token_key').length != cookieCharLimit) {
      this.router.navigate(['/']);
      return;
    }
    let redisData = {"key":'cachetoken_'+ this.cookieService.get('token_key'),"value":data,"cacheValidity": cacheValidity}
    if(this.cookieService.get('token_key') && cacheValidity){
      this.http.post<any>(url,redisData).subscribe(dataset => {this.loggerService.logInfo(dataset);});
        if(isPlatformBrowser(this.platformId)){
          this.http.get<any>(sessionUrl).subscribe(dataset => {this.loggerService.logInfo(dataset);});
      }
    }
  }
  clearRedisData(url:any){
    const endPoint = '/api/clearRedisData';
    const sanitizedUrl = decodeURIComponent(encodeURIComponent(url.redirectionUrl));
    if (!sanitizedUrl || sanitizedUrl === '') {
      return;
    }
    this.http.get<any>(endPoint).subscribe(dataset => {
      window.location.assign(sanitizedUrl);
      this.loggerService.logInfo(dataset);
    });
  }
// delete cache data for get new course data on logout
  deleteRedisData(): Observable<any>{
    if (GlobalSettings.isDesktop) {
      const url: string = GlobalSettings.LocalNodeServerBaseUrl + '/api/login/deleteCacheAPI';
      return this.http.request('GET', url)
      .pipe(
        tap(response => {
          return response;
        }),
        catchError(this.handleError('deleteRedis', []))
      );
    } 
    else if (GlobalSettings.isNative) {
      return new Observable(observer => {
        deleteRedisAPICallback((res: any) => { observer.next(res) });
      });
    } else {
      if(!this.data.aceToken){
        return of([]);
      }
      let url: string = this.baseUrl + ApiConstants.deleteCacheData;
      return this.http.get<any>(url);
    }
}

  setACEToken(token: ACEToken) {
    return new Promise((resolve, reject) => {
      if (token) {
        this.data.aceToken = token;
        if(this.data.userInfo){
        this.data.userInfo.aceToken = token;
        this.data.userInfo.aceTokenString =  JSON.stringify(token);
        if(GlobalSettings.isNative){
          const varibales = {
            'user_info': this.data.userInfo
          };
          saveAngularVariablesToMobileDevice(varibales, (data:any) => {
            this.loggerService.logInfo(data);
          });
        }
        }
        this.data.machineIdentifier = this.data.aceToken.MachineIdentifier;
        resolve(true);
      } else {
        resolve(false);
      }
    });
  }
 
  setMEEToken(token: MEEToken) {
    if (token) {
      this.data.meeToken = token;
      this.data.userInfo.meeToken = token;
      this.data.userInfo.meeTokenString =  JSON.stringify(token);
    }
  }

  loadACEToken() {
    let aceTokenString = this.data.userInfo.aceTokenString;
    if (typeof (aceTokenString) !== 'undefined' && aceTokenString !== null && aceTokenString !== '') {
      let aceToken: ACEToken = JSON.parse(aceTokenString);
      this.data.aceToken = aceToken;
      this.data.userInfo.aceToken = aceToken;
    }
  }

  loadMEEToken() {
    let meeTokenString = this.data.userInfo.meeTokenString;
    if (typeof (meeTokenString) !== 'undefined' && meeTokenString !== null && meeTokenString !== '') {
      let meeToken: MEEToken = JSON.parse(meeTokenString);
      this.data.meeToken = meeToken;
      this.data.userInfo.meeToken = meeToken;
    }
  }

  getMeeToken(): Observable<any> {
   if (GlobalSettings.isBrowser) {
     return this.getMeetokenForBrowser();
   } else if (GlobalSettings.isNative) {
     return this.getMeetokenForNative();
   } else {
     return this.getMeetokenForDesktop();
   }
  }

  getMeetokenForBrowser() {
    let url: string = `${this.baseUrl}${ApiConstants.meeTokenUrl}?env=${environment.name}`;
    return this.http.get<any>(url)
      .pipe(
        tap(response => { this.loggerService.logInfo('response'); }),
        catchError(this.handleError('getMeeToken', new Error()))
      );
  }

  getMeetokenForNative(): Observable<any> {
    return new Observable(observer => {
      getMeeToken((result: any) => {
        result = JSON.parse(result);
        observer.next(result);
      });
    });
  }

  getMeetokenForDesktop() {
    const url: string = `${GlobalSettings.LocalNodeServerBaseUrl}/api/account/getMeeToken`;
    const params = new HttpParams().set('env', environment.name);
    return this.http.request<any>('GET', url, { responseType: 'json', params })
      .pipe(
        tap((response) => {this.loggerService.logInfo('response'); }),
        catchError(this.handleError('getMeeToken', new Error()))
      );
  }

  handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      return of(result as T);
    };
  }
}
