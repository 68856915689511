import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { GlobalSettings } from 'src/app/global.settings';

@Injectable({
    providedIn: 'root'
})
export class LoggerService {
  
    constructor(
        private http: HttpClient,
    ) { }

    public logError(logMessage: string): any{
      if (!GlobalSettings.isNative) {
        if(!GlobalSettings.isBrowser){
          const url: string = GlobalSettings.LocalNodeServerBaseUrl + '/api/logger/errorLogger';
          return this.http.post<any>(url, { loggerMessage: logMessage }) .pipe(
              tap(data => {
                this.log(`logError`);
              }),
              catchError(this.handleError('logError error', false))
            ).subscribe();
        }
      } else {
        this.logToNative('error', logMessage);
      }

    }
    public logWarn(logMessage: string): any {
      if (!GlobalSettings.isNative) {
          if(!GlobalSettings.isBrowser){
          const url: string = GlobalSettings.LocalNodeServerBaseUrl + '/api/logger/warnLogger';
          return this.http.post<any>(url, { loggerMessage: logMessage }).pipe(
              tap(data => {
                this.log(`logWarn`);
              }),
              catchError(this.handleError('logWarn error', false))
            ).subscribe();
          }
        } else {
          this.logToNative('warn', logMessage);
        }
    }

    public logInfo(logMessage: string): any {
        if (!GlobalSettings.isNative) {
        if(!GlobalSettings.isBrowser){
            const url: string = GlobalSettings.LocalNodeServerBaseUrl + '/api/logger/infoLogger';
            return this.http.post<any>(url, { loggerMessage: logMessage }).pipe(
                tap(data => {
                    this.log(`logInfo`);
                }),
                catchError(this.handleError('logInfo error', false))
            ).subscribe();
            }
        } else {
            this.logToNative('info', logMessage);
        }
    }

    public logVerbose(logMessage: string): any {
      if (!GlobalSettings.isNative) {
          if(!GlobalSettings.isBrowser){
          const url: string = GlobalSettings.LocalNodeServerBaseUrl + '/api/logger/verboseLogger';
          return this.http.post<any>(url, { loggerMessage: logMessage }).pipe(
              tap(data => {
                this.log(`logVerbose`);
              }),
              catchError(this.handleError('logVerbose error', false))
            ).subscribe();
          }
        } else {
          this.logToNative('verbose', logMessage);
        }
    }

    public logDebug(logMessage: string): any {
      if (!GlobalSettings.isNative) {
        if(!GlobalSettings.isBrowser){
        const url: string = GlobalSettings.LocalNodeServerBaseUrl + '/api/logger/debugLogger';
        return this.http.post<any>(url, { loggerMessage: logMessage }).pipe(
            tap(data => {
              this.log(`logDebug`);
            }),
            catchError(this.handleError('logDebug error', false))
          ).subscribe();
        }
        } else {
          // this.logToNative('debug', logMessage);
        }
    }

    private logToNative(logLevel: string, logMessage: string) {

      const data = {
        'level': logLevel,
        'message': logMessage
      };
      log(data, (response:any) => {
        response = JSON.parse(response);
      });
    }

    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
    
          this.log(`${operation} failed: ${error.message}`);
          return of(result as T);
        };
    }

    public log(message: string) {
        console.error(message);
    }

}
