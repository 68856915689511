export var MenuList: any = 
 [
    { id: 'bookshelf', hierarchyLevel: '0', name: 'Bookshelf', langKey: 'bookshelf', icon: 'icon-bookshelf', url: '/courses/courselist', ishide:'true', queryParams: { bookself : 'true'}},
    { id: 'adminCalss', hierarchyLevel: '1', name: 'Class setup', langKey: 'classAdmin', icon: 'icon-users', url: '', queryParams: {} },
    { id: 'School', hierarchyLevel: '2', name: 'School work',langKey:"schoolWork", icon: 'icon-briefcase', trailingIcon: 'icon-chevron-right', isContainSubMenu: true,
        children: [
            { id: 'schoolTeacherAssessment', ishide:'false', parentId: '2', hierarchyLevel: '2_0', name: 'Assessment',langKey:"schoolWorkAssessment",  icon: 'icon-chevron-right', isContainSubMenu: true,
            children: [
                { id: 'teacherAssessmentDashboard', parentId: '2_0', hierarchyLevel: '2_0_0', name: 'Dashboard',langKey:"schoolWorkTeacherDashboard", icon: 'icon-calendar', url: '/homework-assessment/dashboard/assessment', queryParams: { menuHierarchy: '2_0' } },
                { id: 'teacherAssessmentMyquestion', parentId: '2_0', hierarchyLevel: '2_0_1', name: 'My questions',langKey:"schoolWorkTeacherQuestions", icon: 'icon-calendar', url: '/homework-assessment/assessment/builder/myquestion', queryParams: { menuHierarchy: '2_0' } }
            ]},
            { id: 'schoolAssessment', ishide:'false', parentId: '2',hierarchyLevel: '2_0', name: 'Assessment', langKey:"schoolWorkAssessmentApp",  icon: 'icon-calendar', url: "/homework-assessment-student/dashboard/assessment", queryParams: { menuHierarchy: '2' } },
            { id: 'schoolhomeworkapp', ishide:'false', parentId: '2',hierarchyLevel: '2_1', name: 'Homework',langKey:"schoolWorkHomeworkApp", trailingIcon: 'icon-chevron-right', isContainSubMenu: true,
            children: [
                { id: 'HomeworkDashboard', parentId: '2_1', hierarchyLevel: '2_1_0', name: 'Dashboard',langKey:"schoolWorkHomeworkDashboard", icon: 'icon-calendar', url: '/homework-assessment-student/dashboard/homework', queryParams: { menuHierarchy: '2_1' } },
                { id: 'HomeworkDownloads', parentId: '2_1', hierarchyLevel: '2_1_1', name: 'Downloads',langKey:"schoolWorkHomeworkDownload", icon: 'icon-calendar', url: '/homework-assessment-student/dashboard/downloads', queryParams: { menuHierarchy: '2_1' } }
            ]},
            { id: 'schoolhomeworkteacher', ishide:'false', parentId: '2', hierarchyLevel: '2_1', name: 'Homework',langKey:"schoolWorkTeacherHomework", trailingIcon: 'icon-chevron-right', isContainSubMenu: true,
            children: [
                { id: 'homeworkdashboard', parentId: '2_1', hierarchyLevel: '2_1_0', name: 'Dashboard',langKey:"schoolWorkTeacherHomeworkDashboard",  icon: 'icon-calendar', url: '/homework-assessment/dashboard/homework', queryParams: { menuHierarchy: '2_1' } },
                { id: 'homeworkmyactivities', parentId: '2_1', hierarchyLevel: '2_1_1', name: 'My activities', langKey:"schoolWorkTeacherHomeworkActivities", icon: 'icon-calendar', url: '/homework-assessment/homework/builder/myactivity', queryParams: { menuHierarchy: '2_1' } }
            ]},
            { id: 'schoolHomework', ishide:'false', parentId: '2', hierarchyLevel: '2_1', name: 'Homework',langKey:"schoolWorkHomework", url: '/homework-assessment-student/dashboard/homework', queryParams: { menuHierarchy: '2' } },
            { id: 'schoolProgress', parentId: '2', hierarchyLevel: '2_2', name: 'Progress',langKey:"schoolWorkProgress", icon: 'icon-bar-chart-square', url: "/school-work/progress", queryParams: { menuHierarchy: '2' } },
            { id: 'schoolGrading', parentId: '2', hierarchyLevel: '2_3', name: 'Grading',langKey:"schoolWorkGrading", icon: 'icon-backpack', url: "/school-work/grading", queryParams: { menuHierarchy: '2' } },
            { id: 'schoolCalendar', parentId: '2', hierarchyLevel: '2_4', name: 'Calendar',langKey:"schoolWorkCalendar",  icon: 'icon-calendar', url: "/school-work/calendar", queryParams: { menuHierarchy: '2' } },
            { id: 'coursebuilder', parentId: '2', hierarchyLevel: '2_5', name: 'Course Builder',langKey:"courseBuilder",  icon: '', url: "/course-builder/student-dashboard", queryParams: { menuHierarchy: '2' } }
        ]
    },
    { id: 'Notifications', hierarchyLevel: '3', name: 'Notifications', langKey: 'notifications', icon: 'icon-bell', badge: 0, url: "/notifications", queryParams: {currentPage:1,selectedDropdownOptionId:5,dropdownId:'paginationIdPageSizeList'}},
    { id: 'settings', hierarchyLevel: '4', name: 'Settings',langKey:"settings",  icon: 'icon-settings', trailingIcon: 'icon-chevron-right', isContainSubMenu: true,
        children: [
            { id: 'personaldetails', parentId: '4', hierarchyLevel: '4_0',  name: "Personal details", langKey:"settingsPersonalDetail", trailingIcon: "icon-link-external", trailingLabel:'open in new window', url: "/courses/courselist/settings/myprofile", externalUrl: "userProfile/view" },
            { id: 'about',  parentId: '4', hierarchyLevel: '4_1', name: 'About', langKey: 'settingsAbout', icon: 'icon-chevron-right', ishide:'false', isContainSubMenu: true,
            children: [
                { id: 'cookiepolicy', parentId: '4_1', hierarchyLevel: '4_1_0', name: 'Cookie policy', langKey:"helpPoliciesCookiesPolicy", icon: '', url: "https://www.macmillaneducationeverywhere.com/cookies-policy", trailingIcon: 'icon-link-external', trailingLabel:'open in new window', queryParams: { menuHierarchy: '4_1' } },
                { id: 'privacypolicy', parentId: '4_1', hierarchyLevel: '4_1_1', name: 'Privacy policy', langKey:"helpPoliciesPrivacyPolicy", icon: '', url: "https://www.macmillaneducationeverywhere.com/privacy-policy", trailingIcon: 'icon-link-external', trailingLabel:'open in new window', queryParams: { menuHierarchy: '4_1' } },
                { id: 'termsconditions', parentId: '4_1', hierarchyLevel: '4_1_2', name: 'Terms & conditions', langKey:"helpPoliciesTermCondition", icon: '', url: "https://www.macmillaneducationeverywhere.com/terms-conditions", trailingIcon: 'icon-link-external', trailingLabel:'open in new window', queryParams: { menuHierarchy: '4_1' } },
                { id: 'systemrequirements', parentId: '4_1', hierarchyLevel: '4_1_3', name: 'System requirements', langKey:"helpPoliciesSystemRequirement", icon: '', url: "https://www.macmillaneducationeverywhere.com/system-requirements", trailingIcon: 'icon-link-external', trailingLabel:'open in new window', queryParams: { menuHierarchy: '4_1' } },
            ]},
            { id: 'classmanagement',  parentId: '4', hierarchyLevel: '4_2', name: 'Joining code / class code', langKey: 'classManagement', icon: '', url: "/settings/class-management", queryParams: {menuHierarchy: '4'} },
            { id: 'deleteaccount',  parentId: '4', hierarchyLevel: '4_3', name: 'Delete account', langKey: 'deleteAccount', icon: '',trailingIcon: 'icon-link-external', trailingLabel:'open in new window', url: "https://www.macmillaneducationeverywhere.com/help-centre#DeleteAccount", queryParams: {menuHierarchy: '4_3'} },
            { id: 'sendreport', parentId: '4', hierarchyLevel: '4_4', name: 'Send error report', langKey: 'SendErrorReport', icon: 'icon-alert-circle', url: "/courses/courselist/error-report",isMobile:true, queryParams: {} },
            { id: 'language', parentId: '4', hierarchyLevel: '4_5', name: 'Language', langKey: 'language', icon: '', url: "/settings/languageSwitcher", queryParams: { menuHierarchy: '4' } },
            { id: 'cookiesettings', parentId: '4', hierarchyLevel: '4_6', name: 'Cookie settings', langKey: 'cookiesettings', icon: '', queryParams: { menuHierarchy: '4' } },

        ]
    },
    { id: "Help_Policies", hierarchyLevel: '5', name: "Help",langKey: 'helpPoliciesHelp', icon: "icon-help-circle", trailingIcon: 'icon-link-external', trailingLabel:'open in new window', url: "/courses/courselist/help", queryParams: { help :'true' }},
    { id: "Logout", hierarchyLevel: '6', name: "Logout", langKey: 'logout', icon: "icon-log-out", url: "/logout", queryParams: {} },
    { id: 'coursebuilder', hierarchyLevel: '7', name: 'Course Builder',langKey:"courseBuilder", icon: '', trailingIcon: 'icon-chevron-right', isContainSubMenu: true,
        children: [
            { id: 'coursebuilderdashboard', parentId: '7', hierarchyLevel: '7_0', name: 'Dashboard', langKey: "courseBuilderDashboard", icon: '', url: '/course-builder/dashboard', queryParams: { menuHierarchy: '7' } },
            { id: 'coursebuilderactivities', parentId: '7', hierarchyLevel: '7_1', name: 'My activities', langKey: "courseBuilderActivities", icon: '', url: '/course-builder/cb-myactivities', queryParams: { menuHierarchy: '7' } }
        ]
    },
    { id: 'theme', hierarchyLevel: '8', name: 'Theme',langKey: 'theme', isTitle: true, hasTheme: true, isContainSubMenu: true, queryParams: { hasTheme: true },
        children : [
            { id: 'regularTheme' ,parentId: '8', langKey: 'basic', hierarchyLevel: '8_0', name: 'Basic', isTitle: true },
            { id: 'kidsThemesTheme', hierarchyLevel: '8_2', langKey: 'fun', name: 'Fun', isTitle: true },
        ]
    }
];