import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MacedFrontendAngularComponentModule } from '@maced/angular-components';
import { ContinueCardComponent } from './components/continue-card/continue-card.component';
import { DialogNoJsComponent } from './components/dialog-no-js/dialog-no-js.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HomeworkService } from '../core/services/homework.service';
import { DataMigrationStatusComponent } from './components/data-migration-status/data-migration-status.component';
import { BringBackDialogComponent } from './components/bring-back-dialog/bring-back-dialog.component';
import { UtcToLocalPipe } from './pipe/utc-to-local.pipe';
import { SafeHtmlPipe } from './pipe/safe-html.pipe';
import { GotoPageComponent } from './components/goto-page/goto-page.component';
import { PerformancePopupComponent } from './performance-popup/performance-popup.component';
import { DownloadPackageComponent } from './components/download-package/download-package.component';
import { OfflinePackageCreatedComponent } from './components/offline-package-created/offline-package-created.component';
import { NoUsbComponent } from './components/no-usb/no-usb.component';
import { TransferUnsuccessfulComponent } from './components/transfer-unsuccessful/transfer-unsuccessful.component';
import { MultipleUsbComponent } from './components/multiple-usb/multiple-usb.component';
import { NoInternetConnectionDialogComponent } from './components/no-internet-connection-dialog/no-internet-connection-dialog.component';
import { NotEnoughSpaceDialogComponent } from './components/not-enough-space-dialog/not-enough-space-dialog.component';
import { ErrorOccursDialogComponent } from './components/error-occurs-dialog/error-occurs-dialog.component';
import { TermsAndConditionContentComponent } from './terms-and-condition-content/terms-and-condition-content.component';
import { TermsAndConditionDialogComponent } from './terms-and-condition-dialog/terms-and-condition-dialog.component';
import { SessionLimitReachedComponent } from './components/session-limit-reached/session-limit-reached.component';


@NgModule({
  declarations: [
    ContinueCardComponent,
    DialogNoJsComponent,
    DataMigrationStatusComponent,
    BringBackDialogComponent,
    UtcToLocalPipe,
    SafeHtmlPipe,
    GotoPageComponent,
    PerformancePopupComponent,
    DownloadPackageComponent,
    OfflinePackageCreatedComponent,
    NoUsbComponent,
    TransferUnsuccessfulComponent,
    MultipleUsbComponent,
    NoInternetConnectionDialogComponent,
    NotEnoughSpaceDialogComponent,
    ErrorOccursDialogComponent,
    TermsAndConditionContentComponent,
    TermsAndConditionDialogComponent,
    SessionLimitReachedComponent
  ],
  exports: [
    MacedFrontendAngularComponentModule,
    ContinueCardComponent,
    DialogNoJsComponent,
    BringBackDialogComponent,
    UtcToLocalPipe,
    SafeHtmlPipe,
    GotoPageComponent,
    PerformancePopupComponent,
    DownloadPackageComponent,
    OfflinePackageCreatedComponent,
    NoUsbComponent,
    TransferUnsuccessfulComponent,
    MultipleUsbComponent,
    NoInternetConnectionDialogComponent,
    NotEnoughSpaceDialogComponent,
    ErrorOccursDialogComponent,
    TermsAndConditionDialogComponent,
    TermsAndConditionContentComponent,
    SessionLimitReachedComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MacedFrontendAngularComponentModule
  ],
  providers: [
    HomeworkService
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
})
export class SharedModule { }