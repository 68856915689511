import { ChangeDetectorRef, Component, HostListener, Inject, OnInit, PLATFORM_ID, Renderer2, ViewEncapsulation } from '@angular/core';
import { LanguageExtService } from 'src/app/services/language-ext.service';
import { GlobalSettings } from 'src/app/global.settings';
import { CdnAssets, IConstants } from '../../core/models/constants';
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import { CommonService } from '../../core/services/common.service';
import { UtilityService } from 'src/app/services/utility.service';
import { environment } from 'src/environments/environment';
import { Data } from 'src/app/core/models/data';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { delay, of } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  host: {
    class: 'footer-block',
    '(click)': 'openWebView($event)',
  },
  encapsulation: ViewEncapsulation.None
})
export class FooterComponent implements OnInit {
  baseURL: string = this.constants.baseURL;
  myClassesURL: string = this.constants.myClassesURL;
  companyNo: string = this.constants.companyNo;
  VATNumber: string = this.constants.VATNumber;
  companyName: string = this.constants.companyName;
  version: string = this.constants.version;
  copyright = '';
  emptySpace = ' ';
  imageLogoPath: string = GlobalSettings.routePath + "/assets/default/images/footer-logo.svg";
  footerLogoLink: string = GlobalSettings.isNative ? "/" : this.constants.footerLogoLink;
  footerLogoTarget: string = GlobalSettings.isNative ? "_self" : "_blank";
  links: { label: string, url?: string, target?: string }[] = [];
  showFooter: boolean = false;
  currentUrl: string = "";
  elemClass: string = "";
  showActivityFooter: boolean = false;
  title:string='';
  subtitle:string='';
  isCookiePopupEnabled: boolean = false;
  constructor(private languageExtService: LanguageExtService,
    private constants: IConstants,
    private commonService: CommonService,
    public utilityService: UtilityService,
    public router: Router,
    public data: Data,
    private _cd: ChangeDetectorRef,
    public cookieService: SsrCookieService,
    private _renderer: Renderer2,
    private _activeRoute: ActivatedRoute,
    @Inject(PLATFORM_ID) public platformId: Object
  ) {

    if(GlobalSettings.isBrowser) {
      this.imageLogoPath = environment.assetsCdnUrl + CdnAssets.footerLogoCdn;
    }

    // Fetch the data fron global state to add it to local state variable.
    this.languageExtService.languageLoadNotifier.subscribe((data) => {
      if (this.data && this.data.languageJson) {
        this.setFooterItemsBasedOnLangData(this.data.languageJson);
      }
    });
    this.showCookieMenuInSelectedDevice();
  }

  ngOnInit() {
    
    this.setFooterItems();
    
    this.data.footerTitle.subscribe( title =>{
      this.title = title
    })
    this.data.footerSubtitle.subscribe( subtitle =>{
      this.subtitle = subtitle
    });
    this.data.hideheaderFooter.subscribe( hiddenRes =>{
      if(hiddenRes){
        this.showFooter  = false;
      }
      else{
        this.showFooter = true;
      }
      this._cd.detectChanges();
    })

    

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url;
        const currentUrlWithoutqueryParam = event.url.split("?")[0];
        if (currentUrlWithoutqueryParam === '/login' || currentUrlWithoutqueryParam === '/download' || currentUrlWithoutqueryParam === '/' || currentUrlWithoutqueryParam === '/logout' || currentUrlWithoutqueryParam === '' || currentUrlWithoutqueryParam === '/#page__content' || (GlobalSettings.isBrowser && !this.data.isAuthenticated && this.cookieService.get('isKMLI_ssr') !== 'true') && !currentUrlWithoutqueryParam.includes('/bookviewer')) {
          this.showFooter = true;
        }
        else {
          this.showFooter = false;
        }
        if(this.currentUrl.includes('/homework-assessment/selectactivity')){
          this.showFooter = true;
          this.showActivityFooter = true;
        }
        else{
          this.showActivityFooter = false;
        }
      }
    });
    this._activeRoute.queryParams.subscribe(param=>{
      this.dataCCAction();
    })
  }

  dataCCAction() {
    of(null).pipe(delay(500)).subscribe(() => {
      const footerBtn = document.getElementsByClassName('c-footer__nav-list-item-link')[0];
      if (footerBtn) {
        this._renderer.setAttribute(footerBtn, 'data-cc-action', 'preferences');
      }
    });
  }

  setFooterItems() {
    this.copyright = '<span class="c-footer__copy-right--company-name">' + this.companyName + this.emptySpace + new Date().getFullYear() + '</span>' + this.emptySpace + '<span class="c-footer__copy-right--company-number">' +
    this.companyNo + '</span>' + this.emptySpace + '<span class="c-footer__copy-right--vat-number">' + this.VATNumber + '</span>' + this.emptySpace + '<span class="c-footer__copy-right--version">' + this.version + this.emptySpace + environment.applicationBuildVersion + '</span>';
    this.links = this.constants.FOOTER_LINK;
  }

  setFooterItemsBasedOnLangData(data: any) {
    let footerLinks = [{ label: data.login?.footer_link?.tc_link, srOnlyLabel:data.global_accessibility?.new_window_label, url: this.baseURL + "terms-conditions/", target: '_blank' },
    { label: data.login?.footer_link?.privacy_policy_link, srOnlyLabel:data.global_accessibility?.new_window_label, url: this.baseURL + "privacy-policy/", target: '_blank' },
    { label: data.login?.footer_link?.cookies_policy_link, srOnlyLabel:data.global_accessibility?.new_window_label, url: this.baseURL + "cookies-policy", target: '_blank' },
    { label: data.login?.footer_link?.sys_req_link, srOnlyLabel:data.global_accessibility?.new_window_label, url: this.baseURL + "system-requirements/", target: '_blank' },
    { label: data.login?.footer_link?.parents_link, srOnlyLabel:data.global_accessibility?.new_window_label, url: this.baseURL + 'for-parents', target: '_blank' }
  ];

  if(this.isCookiePopupEnabled){
    footerLinks.push({ label: data.menu?.cookiesettings} as any ) ;
  }

    if (GlobalSettings.isNative) {
      let NativefooterLinks = [{ label: data.login.footer_link.tc_link, srOnlyLabel:data.global_accessibility?.new_window_label, url: this.baseURL + "terms-conditions/"},
      { label: data.login.footer_link.privacy_policy_link, srOnlyLabel:data.global_accessibility?.new_window_label, url: this.baseURL + "privacy-policy/"},
      { label: data.login.footer_link.cookies_policy_link, srOnlyLabel:data.global_accessibility?.new_window_label, url: this.baseURL + "cookies-policy"},
      { label: data.login.footer_link.sys_req_link, srOnlyLabel:data.global_accessibility?.new_window_label, url:  this.baseURL + "system-requirements/"},
      { label: data.login.footer_link.parents_link, srOnlyLabel:data.global_accessibility?.new_window_label, url: this.baseURL + 'for-parents'}];
      this.links = NativefooterLinks;
    } else {
      this.links = footerLinks;
    }
    this.companyName = data.login?.footer_link.org_name;
    this.companyNo = data.login?.footer_link.company_number;
    this.VATNumber = data.login?.footer_link.vat_number;
    this.version = data.login?.footer_link.version;
    this.copyright = '<span class="c-footer__copy-right--company-name">' + this.companyName + this.emptySpace + new Date().getFullYear() + '</span>' + this.emptySpace + '<span class="c-footer__copy-right--company-number">' +
    this.companyNo + '</span>' + this.emptySpace + '<span class="c-footer__copy-right--vat-number">' + this.VATNumber + '</span>' + this.emptySpace + '<span class="c-footer__copy-right--version">' + this.version + this.emptySpace + environment.applicationBuildVersion + '</span>';
  }

  openWebView = (event: any) => {
    if (GlobalSettings.isNative) {
      let externalUrl = '';
      this.elemClass = event.target.classList[0];
      if ((this.elemClass === 'c-list__item-link') || (this.elemClass === 'c-footer__nav-list-item-link')) {
        let evtText = event.target.innerText;
        let externalUrlSuffix =
          evtText === 'Terms and conditions'
            ? 'terms-conditions/'
            : evtText === 'Privacy policy'
            ? 'privacy-policy/'
            : evtText === 'Cookies policy'
            ? 'cookies-policy/'
            : evtText === 'System requirements'
            ? 'system-requirements/'
            : 'for-parents';
        externalUrl = this.baseURL + externalUrlSuffix;
        this.commonService.openExternalURLCallback(externalUrl);
      } else if (this.elemClass === 'c-footer__logo-img') {
        externalUrl = this.constants.footerLogoLink;
        this.commonService.openExternalURLCallback(externalUrl);
      }
    }
  }
  public showCookieMenuInSelectedDevice(){
    let nojs=isPlatformBrowser(this.platformId);
    if(GlobalSettings.isBrowser && nojs){
      this.isCookiePopupEnabled=true;
    }
  }
}
