import { isPlatformServer } from '@angular/common';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { makeStateKey, StateKey, TransferState } from '@angular/platform-browser';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { Request } from 'express';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { GlobalSettings } from 'src/app/global.settings';
import { Data } from '../models/data';
const { detect } = require('detect-browser');
const browser = detect();
@Injectable()
export class interceptor implements HttpInterceptor {
    authReq:any;
    constructor(
        @Inject(PLATFORM_ID) private platformId: object, @Optional() @Inject(REQUEST) protected request: Request, private data: Data, private transferState: TransferState, private cookieService: SsrCookieService) { }
    intercept(req: HttpRequest<any>, next: HttpHandler) {
        const authToken: any = this.data.aceToken;        
        this.authReq = undefined;
        if (authToken && authToken.length != 0) {
            if (GlobalSettings.isCoreApi) {
                this.authReq = req.clone({
                    setHeaders: {
                        'AccessToken': authToken.access_token,
                        'RefreshToken': authToken.refresh_token,
                        'MachineIdentifier': authToken.MachineIdentifier,
                        'PCheck': authToken.Role,
                        'MEEUserId': authToken.MEEUserId
                    }
                });
                if (req.url.includes('getsubscriptiondetails?isClearCache=true')) {
                    let cookieKey = this.authReq.headers.append('CookieKey', this.cookieService.get('token_key'));
                    this.authReq = req.clone({
                        headers: cookieKey
                    })
                }
            }
            else {
                this.authReq = req.clone({
                    setHeaders: {
                        'Authorization': 'bearer ' + authToken.access_token,
                        'Refreshtoken': authToken.refresh_token,
                        'MachineIdentifier': authToken.MachineIdentifier,
                        'PCheck': authToken.Role,
                        'ClientId': GlobalSettings.MACXClientId
                    }
                });
            }
            if (req.method === "POST") {
                return next.handle(this.authReq).pipe(tap((response: HttpEvent<any>) => {
                    return response;
                }));
            }
            else {
                const key: StateKey<string> = makeStateKey<string>(req.url);
                if (isPlatformServer(this.platformId)) {
                    // serverSide
                    return next.handle(this.authReq).pipe(tap((event) => {
                        this.transferState.set(key, (<HttpResponse<any>>event).body);
                    }));
                }
                else {
                    // browserside
                    const storedResponse = this.transferState.get<any>(key, null);
                    if (storedResponse) {
                        const response = new HttpResponse({ body: storedResponse, status: 200 });
                        this.transferState.remove(key);
                        return of(response);
                    }
                    else {
                        return next.handle(this.authReq).pipe(tap((response: HttpEvent<any>) => {
                            return response;
                        }));
                    }
                }
            }
        } else {
            return next.handle(req).
                pipe(catchError((error: HttpErrorResponse) => {
                    return throwError(() => error);
                })
                );
        }
        
    }
}