import { ChangeDetectorRef, Component, Input, NgZone } from '@angular/core';
import {  UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Data } from '../core/models/data';
import { LinkOrigin, VIEW_DOWNLOAD_BTN } from '../core/models/enum';

import { CommonService } from '../core/services/common.service';
import { CourseService } from '../core/services/course.service';
import { UtilityService } from '../services/utility.service';
import { SidepanelService } from '../side-panel/sidepanel.service';

const languageDataSrc = require('src/assets/language/meeLanguageTranslation_en.json');

@Component({
	selector: 'app-error-page',
	templateUrl: './error-page.component.html',
	styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent {
  @Input() errorOptions: any = {source: 'eReader', flag: false};
	checkboxForm!: UntypedFormGroup;
	contentTitle: string ="";
	contentTextMain: string ="";
	contentTextSub: string = "";
	myDownloadsText: string="";
	checkboxMessage: string = 'Don\'t show this again';
	checkBboxNgModelNojs: boolean = false;
	checkInternetConnectLoader: boolean = false;
	isUserLoggedIn: boolean = false;
	timeLeft = 10;
	subscriptionId: any;
	private subscription!: Subscription;
	public tryAgain?:string;
	public cancel?:string;

  constructor(
    private _cd: ChangeDetectorRef,
    public commonService: CommonService,
    public data: Data,
    public courseService: CourseService,
    private router: Router,
    public utilityService: UtilityService,
	public sidePanelService: SidepanelService,
    public ngZone: NgZone
  ) {
   }

  	ngOnInit(){
		this.myDownloadsText = this.data?.languageJson?.navigationMenu?.myDownloads;
		this.contentTitle = this.data.isOfflineUser ? this.data.languageJson?.dashboard?.offlineBookViewer?.error_occured : this.data?.languageJson?.offline?.noInternet;
		this.contentTextMain = this.data.isOfflineUser ? this.data.languageJson?.dashboard?.offlineBookViewer?.error_message : this.data?.languageJson?.offline?.helpMessage;
		this.tryAgain=this.data?.languageJson?.global?.global_button?.tryAgain;
		this.cancel=this.data?.languageJson?.global?.global_button?.cancel;
		this.subscription = this.data.subscriptionId.subscribe((id:any) =>{
			this.subscriptionId = id;
		});
	}

	toggleCancel() {
	this.commonService.globalDekstopOfflineModePopUp$.next(false);
    if(this.errorOptions.source !== 'downloadManager') {
      this.commonService.globalDesktopOfflinePage$.next(false);
      this.commonService.displayReaderPage$.next(false);
    }
		this._cd.detectChanges();
	}

	toggleTryAgain() {
		let _self = this;
		_self.timeLeft = 10;
		_self.checkInternetConnectLoader = true;
		let offlineIntervalCheck = setInterval(() => {
			if (_self.timeLeft > 0) {
				this.utilityService.checkInternetConnectivity().subscribe(internetStatus => {
					if(internetStatus){
						_self.checkInternetConnectLoader = false;
						clearInterval(offlineIntervalCheck);
						this.commonService.globalDekstopOfflineModePopUp$.next(false);
						this.commonService.globalDesktopOfflinePage$.next(false);
						this.router.routeReuseStrategy.shouldReuseRoute=()=>false;
						this.router.onSameUrlNavigation='reload';
						if (this.data.rcfLinkOrigin === LinkOrigin.Homework){
						if(this.errorOptions.source === 'practicetoc'){
							this.commonService.globalDekstopOfflineModeLoadTOC$.next({ source: 'practicetoc',data:this.errorOptions.data, flag: true });
							this.ngZone.run(() => this.router.navigate([`extra-practices/dashboard/${this.subscriptionId}/${_self.data.contentUnitID}`]));
							
						} else if(this.errorOptions.source === 'challengetoc'){
							this.commonService.globalDekstopOfflineModeLoadTOC$.next({ source: 'challengetoc',data:this.errorOptions.data, flag: true });
							this.ngZone.run(() => this.router.navigate([`challenge/dashboard/${this.subscriptionId}/${_self.data.contentUnitID}`]));
							
						}
						else{
							this.ngZone.run(() => this.router.navigate([`bookviewer/${_self.data.contentUnitID}`]));
						}
					}
					}else{
						if (this.data.rcfLinkOrigin === LinkOrigin.Homework){
						if(this.errorOptions.source === 'practicetoc'){
							clearInterval(offlineIntervalCheck);
							this.commonService.globalDekstopOfflineModeLoadTOC$.next({ source: 'practicetoc', flag: false });
							this.toggleCancel();
							this.ngZone.run(() => this.router.navigate([`extra-practices/dashboard/${this.subscriptionId}/${_self.data.contentUnitID}`]));
						} else if(this.errorOptions.source === 'challengetoc'){
							clearInterval(offlineIntervalCheck);
							this.commonService.globalDekstopOfflineModeLoadTOC$.next({ source: 'challengetoc', flag: false });
							this.toggleCancel();
							this.ngZone.run(() => this.router.navigate([`challenge/dashboard/${this.subscriptionId}/${_self.data.contentUnitID}`]));
						}
					}
						else{
						_self.timeLeft--;
						_self.checkInternetConnectLoader = true;
						}
						
					}
				});
			}
			else if (_self.timeLeft == 0) {
				_self.checkInternetConnectLoader = false;
				clearInterval(offlineIntervalCheck);
				this.commonService.displayReaderPage$.next(false);
				this._cd.detectChanges();
			} else {
				_self.timeLeft = 30;
			}
		}, 1000);
	}

	myDownloadRedirect(){
		if (this.errorOptions.source === 'downloads') {
			this.router.navigate(['/homework-assessment-student/dashboard/downloads']);
			this.commonService.globalDekstopOfflineModePopUp$.next(false);
		} else {
			this.sidePanelService.setSidePanelSection('my-downloads');
			this.sidePanelService.isSidePanelOpen.next(true);
			this.commonService.globalDekstopOfflineModePopUp$.next(false);
			this.data.navigateToTab.emit(VIEW_DOWNLOAD_BTN);
		}
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
    }
}
