<button type="button" data-cc-action="preferences" class="cc-preferences-button"> Cookie settings </button>
<ng-container *ngIf="showFooter && !showActivityFooter">
<maced-footer [links]="links"  logoImg={{imageLogoPath}} [copyright]="copyright" logoHyperLink="{{footerLogoLink}}" logoTarget = "{{footerLogoTarget}}" logoImageAltName="Macmillan Education" [hasOpenLink]="true" >
</maced-footer>
</ng-container>
<ng-container *ngIf="showActivityFooter && showFooter">
    <div class="activity-footer">
        <maced-modal-footer>
            <div class="activity-footer-content">
                <div class="activity-footer-text">
                    <div class="activity-footer-text__title"><span class="activity-footer-title">{{title}}</span></div>
                    <div class="activity-footer-text__subtitle"><span class="activity-footer-subtitle">{{subtitle}}</span></div>
                </div>
                
                <maced-button buttonId="Action5" color="secondary" [ariaLabel]="subtitle" icon="icon-chevron-right" position="left"
                  styleClass="u-button-normal"></maced-button>
            </div>
          </maced-modal-footer>
    </div>
</ng-container>