import { Injectable } from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";
import { finalize } from "rxjs/operators";
import { CommonService } from 'src/app/core/services/common.service';
import { Router } from '@angular/router';
import { Data } from 'src/app/core/models/data';
import { GlobalSettings } from 'src/app/global.settings';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  exceptionalRoutes = [
    'checkInternetConnectivity',
    'getFileDownloadedStatus',
    'downloadAssets',
    'downloadAssetBatches',
    'cancelAssetBatches',
    'downloadContent',
    'checkFileExists',
    'download/assets',
    'getmachineidentifier',
    'getresponseurl',
    'bookviewer',
    'selectactivity',
    'deletecalendareventdetails',
    'SaveOrUpdateBookmarkNotes',
    'SaveOrUpdateIWBannotations',
    'saveupdatebooklastaccess',
    'syncLastAccessedBookPage',
    'syncIWBAnnotations',
    'syncBookmarkAndNotes',
    'getuserslatestnotifications',
    'generatepapertestartifact',
    'GetHomeworkAssessmentStudentsGradingSummary',
    'getHomeworkAssessmentStudentsGradingSummary',
    'checkpapertestprogressstatus',
    'saveupdateuserdevicetokendetails',
    'setrediscachefe',
    'getcoursewisestudentprogresssummary',
    'meeLanguageTranslation_en.json',
    'functionality_show_hide.json',
    //browser
    'getactivitylisting',
    //desktop app
    'getActivityListing',
    //desktop
    'getPoolPartyData',
    //browser
    'getpoolpartydata',
    // RMT builder route
    'select-tests',
    // Question-Bank builder route
    'select-questions',
    // homework-assessment meeplatform route
    // Assign student builder route
    'assign-students',
    // Review student builder route
    'student-review',
    'saveUpdateUserDeviceTokenDetails',
    '/api/download/calculatecpu',
    '/api/account/getuserpreference',
    '/api/account/setuserpreference',
    'course-builder',
    'saveUpdateUserDeviceTokenDetails',
    'download/detectUsb',
    'download/copyToUsb',
    'fromTc=true',
    'pro.ip-api.com',
    '/api/setLanguage',
    'getLanguageData',
    'deleterediscache',
    'syncStudentHomeworkDelete',
    '/downloaded_files/',
    '/homeworkDownloadedList/',
    '/addupdateexemptstudenthomeworkassessment',
    '/logger/infoLogger',
    '/download/getdefaultthumbs'
  ];
  logoutExceptionApi = ['deleteCacheAPI', 'getlogouturl', 'deleterediscache', '/login/logout', '/api/login/checkKMLIStatus', '/api/login/getRedis', '/api/homework/syncStudentHomeworkDelete', '/api/homework/syncAudio',
    '/api/homework/syncStudentHomeworkActivity'];

  constructor(
    public loaderService: CommonService, private router: Router,
    public data: Data
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const currentUrl = this.router.url;
    if ((req.url.includes('getrequiresgradingsummary') || req.url.includes('postCommonApi')) && currentUrl.includes('school-work/grading')) {
      if (GlobalSettings.isDesktop) {
        this.exceptionalRoutes.push('postCommonApi');
      } else {
        this.exceptionalRoutes.push('getrequiresgradingsummary');
      }
    } else {
      this.exceptionalRoutes.forEach((Obj: any, index) => {
        if (Obj === 'getrequiresgradingsummary' || Obj === 'postCommonApi') {
          this.exceptionalRoutes.splice(index, 1);
        }
      });
    }
    const foundExceptionalRoutes = this.exceptionalRoutes.filter(obj => req.url.includes(obj));
    const foundExceptionalUrl = this.exceptionalRoutes.filter(obj => this.router.url.includes(obj));
    if (foundExceptionalRoutes.length === 0 && foundExceptionalUrl.length === 0 && this.data.showLoader) {
      if (this.data.enableAdvanceLoading) {
        this.data.showLoaderQ.push(req.url);
      }
      this.loaderService.isLoading_show();
    }
    return next.handle(req).pipe(
      finalize(() => {
        let isLogoutExceptionUrl = true;
        let logoutExceptionUrl = this.logoutExceptionApi.filter(obj => req.url.includes(obj));
        if (logoutExceptionUrl.length > 0 && !GlobalSettings.isBrowser) {
          isLogoutExceptionUrl = false;
        }
        if (this.data.enableAdvanceLoading) {
          if (foundExceptionalRoutes.length === 0 && foundExceptionalUrl.length === 0) {
            const popURL = this.data.showLoaderQ.pop();
            if (!popURL || this.data.showLoaderQ.length < 1) {
              this.loaderService.isLoading_hide();
            }
          }
        } else {
          let isHomeworkSave = (req.url.split('/').pop()== 'addupdatehomeworkassessment?isNewHw=true' || req.url.split('/').pop()== 'addUpdateHomeworkAssessment?isNewHw=true')
          if (foundExceptionalRoutes.length === 0 && foundExceptionalUrl.length === 0 && isLogoutExceptionUrl && !isHomeworkSave) {
            this.loaderService.isLoading_hide();
          }
        }
      })
    );
  }
}
