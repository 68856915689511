<div *ngIf="isLoadingDiv" class="loader">
    <span class="loader-block"></span>
    <label class="loader-title">{{loadingLabel}}...</label>
</div>
<div *ngIf="isLoading_login" class="loader">
  <span class="loader-block"></span>
  <label class="loader-title">{{loadingLabel}}...</label>
</div>
<div *ngIf="isLoading_Kmli" class="loader">
  <span class="loader-block"></span>
  <label class="loader-title">{{loadingLabel}}...</label>
</div>
<div *ngIf="isLoading_rcf" class="loader">
  <span class="loader-block"></span>
  <label class="loader-title">{{loadingLabel}}...</label>
</div>