import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceWith'
})
export class ReplaceWithPipe implements PipeTransform {

  transform(value: string, replaceWith: any, placeholder: string = '\\[\\[placeholder\\]\\]'): string {
    if(typeof value === 'string') {
      const replaceRegex = new RegExp(placeholder, 'ig');
      value = value.replace(replaceRegex, replaceWith);
    }
    return value;
  }

}
