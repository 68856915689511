const  Role =         'user.segment.category';
const  Platform =     'content.category.platform';
const  CourseName =   'content.category.courseName';
const  CourseLevel =  'content.category.courseLevel';
const  BookName =     'content.category.bookName';
const  CourseUnit =   'content.category.courseUnit';
const  CourseLesson = 'content.category.lesson';
const  ActivityType = 'content.category.activityType';
const  UserId  =      'user.profile.profileId';

export const MainMenu = 'Main Menu';
export const DownloadingContent = 'Downloading Content';
export const BookControls = 'Book Controls';
export const BookNavigation = 'Book Navigation';
export const RcfActivityBook = 'RCF Activity - Book';
export const BookMarks = 'Bookmarks - Book';
export const DownloadApp = 'Download - App';
export const Notes = 'Notes - Book';
export const Bookviewer = 'Bookviewer - Book';
export const LessonExtras = 'Lesson Extras - Book';
export const TableOfContent = 'Table Of Content - Book';
export const UpdateContent = 'Content Update - Course';
export const NavigationPanel = 'Navigation Panel - Book';
export const QuickLinks = 'Quick Links - Book';
export const DigitalResources = 'Digital resources - Book';
export const RcfActivityHW = 'RCF Activity - Homework';
export const RcfActivityChallenge = 'RCF Activity - Challenge';
export const RcfActivityPreparation = 'RCF Activity - Preparation & Practice';
export const Homework = 'Homework';
export const IWBAnnotation = 'IWBAnnotation - Book';
export const GlossaryCategory = 'Glossary';
export const ReadAloud = 'Read Aloud - Book';
export const Zoom = 'Zoom - Book';
export const RemoveContent = 'Remove Content';
export const ViewContent = 'Event_EPC_MyDownloads_View_Content';
export const ViewContentBV = 'Event_BookViewer_MyDownloads_View_Content';
export const Login = 'Login Page';
export const Register = 'Register' ;
export const Settings = 'Settings';
export const Calendar = 'Calender';
export const Calendaraddevent = 'Calender Add Event';
export const Calendaraddattendees = 'Calender Add Attendees';
export const Notifications = 'Notifications';
export const EPC_Navigation="Navigation_EPC";
export const EPC_DefaultLaunchActivity="Event_EPC_Launch_DefaultRCF_Activity";
export const EPC_TOC_LaunchActivity="Event_EPC_TOC_LaunchRCF_Activity";
export const EPC_ContinueActivity="Event_EPC_ContinueRCF_Activity";
export const EPC_Exit="Event_EPC_Exit";
export const Challenge_Navigation="Navigation_Challenge";
export const Challenge_DefaultLaunchActivity="Event_Challenge_Launch_DefaultRCF_Activity";
export const Challenge_TOC_LaunchActivity="Event_Challenge_TOC_LaunchRCF_Activity";
export const Challenge_ContinueActivity="Event_Challenge_ContinueRCF_Activity";
export const Challenge_Exit="Event_Challenge_Exit";
export const Challenge_Browser_Activity="Event_Challenge_BrowseActvities";
export const Grading_Navigation="Navigation_Grading";
export const Grading_Exit="Event_Grading_Exit";
export const Progress_Navigation="Navigation_Progress";
export const Progress_Exit="Event_Progress_Exit";
export const Grading_Activity_Feedback_Submit="Event_Grading_Activity_Feedback_Submit";
export const Progress_Tracker_Activity_Feedback_Submit="Event_Progress_Tracker_Activity_Feedback_Submit";
export const ProgressTracker = "Progress Tracker";
export const Grading = "Grading";
export const BookInteractions = "Bookviewer";
export const SearchModule = 'Search - GlobalSearch';
export const CourseDetails = 'Courses - Course Details';
export const BookShelf = 'Courses - BookShelf';
export const PROGRESS_STUDENT_VIEW = "Student";
export const PROGRESS_CLASS_VIEW = "Classes";

export const NOTE_ACTION = {
    "add": "add",
    "export": "export",
    "clicked": "clicked"
}
export const Bookmark_Actions = {
    add: "Add",
    edit: "Edit"
}
export const CHALLENGE_PRACTICE = "Challenge and Extra Practice";
export const VIEW_COURSE_CONTENT = "View Course Content";
export const Progress_Tracker = "Progress Tracker";
export const Activities = "Activities";

export const Assessment = "Assessment";
export const Course_Builder = "Course builder";
export const On_The_Go_Practice = "On-The-Go Practice";
export const Student_Book = "Student's Book";
export const Workbook = "Workbook";
export const Export = 'Export';
class GTMGlobalVariable {
    userId?: string;
    eventValue?: number;
}

class GTMPageViewVariable {
    pageDomain?: string;
    pagePath?: string;
    pageCategory?: string;
    pageTitle?: string;
    country?: string;
    language?: string;
    [UserId]?:string;
    [Role]?:string;
    [Platform]?:string;
    [CourseName]?:string;
    [CourseLevel]?:string;
    [ActivityType]?:string;
    [BookName]?:string;
    [CourseUnit]?:string;
    [CourseLesson]?:string;
}

export enum GTMAction {
    DataTrack = 'data-track',
    PageView = 'custom.historyChange',
    AdvanceTrack = 'advancetrack'
}

export class GtmDataTrackEventObject extends GTMGlobalVariable {
    event?: GTMAction;
    eventAction?: string;
    eventCategory?: string;
    eventLabel?: string;
}

export enum GTMAppTypes {
    Browser = 'Web Browser',
    Native = 'Mobile Device',
    Desktop = 'Application'
}

export class GtmPageViewObject extends GTMPageViewVariable {
    event?: GTMAction;
}

export class GtmAdvanceTrackingObject {
    event?: GTMAction;
    eventCategory?: string;
    eventLabel?: string;
}

export enum GTMEvents {
    ButtonClick = 'button_clicked',
    CalendarEventAdd = 'calendar_event_added',
    CalendarEventJoined = 'calendar_event_joined',
    ErrorAssessment = 'error_assessment',
    FileDownload = 'file_download',
    HomeworkAdded = 'homework_added',
    AssessmentDeleted = 'assessment_deleted',
    Share = 'assessment_shared',
    AssessmentAccessedFromGlobalNav = 'assessment_accessed_from_global_nav',
    AssessmentkAdded = 'assessment_added',
    CopyCourse = 'copy_course',
    CreateCourse = 'create_course',
    CopyHomework = 'copy_homework',
    CopyAssessment = 'copy_assessment',
    Filter = "filters_applied",
    SubmitScore = 'submit_score',
    ViewCourseList = 'view_course_list',
    ViewCourseDetails = 'view_course_details',
    BookLaunched = 'book_launched',
    ClassInstitutionJoin = 'class_institution_join',
    InstitutionJoin = 'institution_join',
    ClassJoin = 'class_join',
    BookZoom = 'book_zoom',
    BookClosed = 'book_closed',
    UnlockAchievement = 'unlock_achievement',
    NavClick = 'nav_click',
    BookPageNavigation = 'book_page_navigation',
    BookAnnotation = 'book_annotations',
    BookZoomAreaToggle = 'book_zoom_area_toggle',
    BookSearchResultsView = 'book_search',
    BookSearchResultsClick = 'book_search_results_click',
    CardView = 'card_view',
    BookNote = "my_notes",
    PageView = 'pageview',
    BookExternalLink = 'book_external_link',
    GoToPage = 'go_to_page',
    Rewards = 'rewards',
    BookQuickLink = 'quick_links',
    ReadAloud = 'read_aloud',
    Bookmarks = 'my_bookmarks',
    ActivityMove = 'activity_move',
    ActivityStart = 'activity_start',
    ActivityFullscreen = 'activity_fullscreen',
    ActivityAnswerShow = 'activity_answers_show',
    FullScreen = 'full_screen',
    RemoveFile = 'remove_file',
    RemoveAllFile = 'remove_allfile',
    DownloadFile = 'download_file',
    DownloadAllFile='download_allfile'
}
