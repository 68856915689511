import { ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { delay, first, of, Subject, takeUntil } from 'rxjs';
import { Data } from 'src/app/core/models/data';
import { ReadiumReaderService } from 'src/app/core/services/readium-reader.service';
import { UtilityService } from 'src/app/services/utility.service';
import { GTMEvents, BookInteractions } from 'src/app/core/models/gtm-models/gtm';
import { GtmService } from 'src/app/core/services/gtm.service';

@Component({
  selector: 'app-goto-page',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './goto-page.component.html',
  styleUrls: ['./goto-page.component.scss']
})
export class GotoPageComponent implements OnInit, OnDestroy{
  formElement!: UntypedFormGroup;
  noOfPages: string = '';
  currentPageNo: string = '';
  hasError: boolean = false;
  closeLabel!: string;
  goLabel!: string;
  ofLabel!: string;
  oldPageValue!: string;
  noOfPageLabel: string;
  ofPageLabel: string;
  public hideGotoButtonFeature: boolean = false;

  private destroy$ = new Subject<boolean>();
  constructor(
    public data: Data,
    public _cd: ChangeDetectorRef,
    public utilityService: UtilityService,
    public readiumReaderService: ReadiumReaderService,
    public formBuilder: UntypedFormBuilder,
    private gtmService : GtmService,
    public renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.setLanguageData();
    this.showCurrentPageNumberSubscribe();
    this.formElement = this.formBuilder.group({
      gotoControl: ['']
    });
    this.currentPageNo = this.data.goToPageNmber;
    this.formElement.controls.gotoControl.reset();
    this.formElement.controls.gotoControl.setValue(this.currentPageNo);
    this.noOfPages = `${this.ofLabel} ${this.data.totalNumberOfPages}`;
    this.noOfPageLabel = `${this.ofPageLabel} ${this.data?.totalNumberOfPages}`;
  }

  setLanguageData() {
    if (this.data.languageJson?.bookviewer) {
      let bookviewerJson = this.data.languageJson?.bookviewer;
      this.closeLabel = bookviewerJson.icons?.close;
      this.goLabel = bookviewerJson.icons?.go;
      this.ofLabel = bookviewerJson.icons?.of;
      this.ofPageLabel = bookviewerJson?.icons?.ofLabel
    }
  }

  goToPageClick() {
    this.utilityService.checkInternetConnectivityFileExist().pipe(takeUntil(this.destroy$)).subscribe(internetStatus => {
      if (internetStatus) {
        let finalPageNo = '';
        let searchPageNo = this.formElement.value.gotoControl.trim().toLowerCase();
        let pageArray = this.data.spotOnData.componentPageZoneMap.bookPageOrder;

        for (let searchPages of pageArray) {
          let pages = searchPages.toLowerCase();
          if (searchPageNo === pages) {
            finalPageNo = pages;
            break;
          }
          else if (pages.includes('-')) {
            const twoPages = pages.split('-');
            if (searchPageNo === twoPages[0]) {
              finalPageNo = twoPages[0];
              break;
            }
            else if (searchPageNo === twoPages[1]) {
              finalPageNo = twoPages[1];
              break;
            }
          }
        }
        if (finalPageNo) {
          if(this.data.isQuicklinksDisabledInBrowser){
            this.data.rightSidePanelOpen.next(false);
          }
          this.readiumReaderService.currentReader$.next({ booktriggerType: `goToSearch`, goToPageInput: finalPageNo });
        }
        else {
          this.hasError = true;
          this._cd.detectChanges();
        }
        this.gtmService.advanceTracking(BookInteractions, GTMEvents.GoToPage, {
          course_name: this.data?.selectedCourse?.courseName,
          course_level: this.data?.selectedCourse?.courseLevel,
          book_name: this.data?.selectedBook?.bookName
        });
      }
    });
  }

  showCurrentPageNumberSubscribe() {
    this.data.updateCurrentPageNumber.subscribe(() => {
      this.hasError = false;
      this.currentPageNo = this.data.goToPageNmber;
      this.formElement.controls.gotoControl.reset();
      this.formElement.controls.gotoControl.setValue(this.currentPageNo);
      this.noOfPages = `${this.ofLabel} ${this.data.totalNumberOfPages}`;
      this.noOfPageLabel = `${this.ofPageLabel} ${this.data?.totalNumberOfPages}`;
      if(this.oldPageValue !== this.currentPageNo) {
        this.utilityService.reRenderQuicklink(this.currentPageNo);
        this.data.isLoading.emit(true)
        }
        this.oldPageValue = this.data.goToPageNmber;
      this._cd.detectChanges();
    });
  }

  goToPageClose() {
    this.data.showGotoForMobile = false;
    this._cd.detectChanges();
  }

  onInputChange(event: any) {
    this.hasError = false;
  }

  footerFullscreenFocusInOut() {
    const element = document.getElementById('ModalHeaderZoom');
    const isFooterChanged = element.classList.contains('c-modal-header--modified') || element.classList.contains('c-modal-header--ios');
    if (element) {
      if (isFooterChanged) {
        this.renderer.removeClass(element, 'c-modal-header--modified');
        this.renderer.removeClass(element, 'c-modal-header--ios');
      } else {
        if(this.isIphone()){
          this.renderer.addClass(element, 'c-modal-header--ios');
        }else{
          this.renderer.addClass(element, 'c-modal-header--modified');
        }
      }
    }
  }

  isIphone(){
    return /iPhone/i.test(navigator.userAgent);
  }

  @HostListener('focusin', ['$event'])
  onFocusIn(event: FocusEvent) {
    if (event.target instanceof HTMLInputElement) {
      this.footerFullscreenFocusInOut();
    }
  }
  @HostListener('focusout', ['$event'])
  onFocusOut(event: FocusEvent) {
    if (event.target instanceof HTMLInputElement) {
      of(null).pipe(delay(500), first()).subscribe(() => {
        this.footerFullscreenFocusInOut();
      });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
