import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { first } from 'rxjs';
import { Data } from 'src/app/core/models/data';
import { GlobalSettings } from 'src/app/global.settings';
import { LanguageExtService } from 'src/app/services/language-ext.service';

@Component({
  selector: 'app-session-limit-reached',
  templateUrl: './session-limit-reached.component.html',
  styleUrls: ['./session-limit-reached.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SessionLimitReachedComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output() closePopup: EventEmitter<any> = new EventEmitter();
  @Input() isLogout = false;
  @Input() isNojs = false;

  sessionReachedErrorImg: string = GlobalSettings.routePath + '/assets/default/images/device-limit-reached.svg';
  logoutErrorImg: string = GlobalSettings.routePath + '/assets/default/images/log-out.svg';
  sessionReachedTitle: string = ``;
  logoutTitle: string = '';
  logoutMessage: string ="";
  sessionReachedMessage: string = '';
  closeLabel: string = '';
  continueLabel: string = '';
  warning: string = '';
  ariaLabels = {
    imageAltText: 'session limit reached'
  };

  constructor(public data: Data, public languageExtService: LanguageExtService,private cdref: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.setLabels();
  }

  setLabels() {
    this.languageExtService.languageLoadNotifier.pipe(first()).subscribe((data) => {
      if (data) {
        const concurrentSession = this.data.languageJson?.concurrentSession;
        this.closeLabel = concurrentSession?.closeLabel;
        this.continueLabel = concurrentSession?.continueLabel;
        this.warning = concurrentSession?.warningLabel;
        this.sessionReachedTitle = concurrentSession?.sessionReachedTitle;
        this.ariaLabels.imageAltText = concurrentSession?.sessionReachedTitle;
        this.sessionReachedMessage = concurrentSession?.sessionReachedMessage;
        this.logoutTitle = concurrentSession?.logoutTitle;
        this.logoutMessage = concurrentSession?.logoutMessage;
        this.cdref.detectChanges();
      }
    })
    if (this.isLogout) {
      const concurrentSession = this.data.languageJson?.concurrentSession;
      this.closeLabel = concurrentSession?.closeLabel;
      this.continueLabel = concurrentSession?.continueLabel;
      this.warning = concurrentSession?.warningLabel;
      this.sessionReachedTitle = concurrentSession?.sessionReachedTitle;
      this.ariaLabels.imageAltText = concurrentSession?.sessionReachedTitle;
      this.sessionReachedMessage = concurrentSession?.sessionReachedMessage;
      this.logoutTitle = concurrentSession?.logoutTitle;
      this.logoutMessage =  concurrentSession?.logoutMessage;
    }

  }

  ngAfterViewInit(): void {
    let dialog = document?.getElementById('sessionLimitReachedDialog');
    dialog?.classList?.add('c-dialog-maximise');
  }

  closeDialog() {
    this.closePopup.emit(true);
  }

  ngOnDestroy(): void {
    this.data.hideheaderFooter.next(false);
    this.data.isSessionLimitReached.next(false);
  }
}
