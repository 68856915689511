import { isPlatformBrowser } from '@angular/common';
import { HttpUrlEncodingCodec } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID, Renderer2, ChangeDetectorRef, ViewEncapsulation, Optional } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { IConstants } from 'src/app/core/models/constants';
import { LOGINPARAMS, getdeviceType } from 'src/app/core/models/enum';
import { Data } from '../core/models/data';
import { AccountService } from '../core/services/account.service';
import { GlobalSettings } from '../global.settings';
import { CommonService } from '../core/services/common.service';
import { AccessibilityService } from '../core/services/accessibility.service';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { Subject, combineLatest, delay, first, of, takeUntil } from 'rxjs';
import { GtmService } from '../core/services/gtm.service';
import { environment } from "src/environments/environment";
import { ApiConstants } from '../core/constant/api.constant';
import { TokenService } from '../core/services/token.service';
import * as s from 'connect-redis';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Login,GTMEvents, Settings } from '../core/models/gtm-models/gtm';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss', '../../app/shared/components/dialog/dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'helpUrl',
    '(click)': 'callNativeforWebview($event)',
  },
})
export class LoginComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<boolean>();
  isSkipUserCheck: string = 'false';
  isRegistration: string = '';
  platform: string = '';
  strAppURL: string = '';
  currentUrl: string = '/';
  curl: string = ApiConstants.siteUrl + '/?id=login&nojs=yes';
  isOCID: string = 'false';
  isPlatformBrowser: boolean = true;
  codec = new HttpUrlEncodingCodec;
  helpUrl: string = this.constants.baseURL + "help-centre";
  helpUrlTarget: string = GlobalSettings.isNative ? "_self" : "_blank";
  loginbuttonText: string = 'Login';
  createAccountbuttonText: string = 'Create account';
  showhelpUrl: boolean = GlobalSettings.isNative ? true : false;
  isUserLoggedIn: boolean = false;
  public globalSystemErrorPage = false;
  public bodyElement = document.body;
  public classesToAdd = ['login-page--transparent', 'login-page--hidden'];
  public classesToRemove = ['login-page--transparent', 'login-page--hidden'];
  public deviceType: any;
  constructor(
    private cookieService: SsrCookieService,
    private accountService: AccountService,
    private router: Router,
    private _meta: Meta,
    public data: Data,
    private constants: IConstants,
    private commonService: CommonService,
    private _renderer: Renderer2,
    private cdref: ChangeDetectorRef,
    private accessibilityService: AccessibilityService,
    private titleService: Title,
    private route: ActivatedRoute,
    public gtmService: GtmService,
    private tokenService: TokenService,
    public deviceDetectorService: DeviceDetectorService,
    @Inject(PLATFORM_ID) private platformId: any,

  ) {
    window.addEventListener("pageshow", this.handlePageShow.bind(this));
    this.commonService.isLoading_show();
    this._renderer.addClass(document.body, 'login-page');
  }
 
  handlePageShow(event: Event) {
    const historyTraversal = (event as any).persisted ||
      (typeof window?.performance != "undefined" &&
        window?.performance?.navigation?.type === 2);
    if (historyTraversal) { this.reloadMethod(); }
  }

  reloadMethod() {
    window.location.reload();
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (params?.path?.includes('courses/courselist')) {
        this.data.isLoadingLgoin.next(true);
      }
    });
    this.dynamicRendering("add")  
    this.checkLoadingDone();
    this.deviceType== this.commonService.getDeviceType();
    this.data.setRedisApiCall = true;
    this.data.loginStatus = false;
    this.titleService.setTitle(this.data.languageJson?.titleBarStatus?.LOGINTITLE);
    this.data.globalHeaderData.emit({headerLogoVisible:false, headerTitle:'', headerSubTitle: '', headerBackUrl: ''});
    this.gtmService.pageViewTracking('Login');    

    if (GlobalSettings.isBrowser || GlobalSettings.isDesktop) {
      if (this.data.aceToken && this.data.aceToken?.access_token != '') {
        if (this.router.url.split("?")[0] == "/logout") {
          this.accountService.logout(true);
          this.cdref.detectChanges();
        } else {
          if (GlobalSettings.isDesktop) {
            this.accountService.setKMLI();
          } else {
            this.data.isLoadingLgoin.next(true);
            this.router.navigate(['courses/courselist'])
          }
          return;
        }
      }
    }
    this.loginCheckCall();
    if (!isPlatformBrowser(this.platformId)) {
      this.isPlatformBrowser = false;
    }
    this.platformCheckCall();
    this.accessibilityService.setNavigationUrl(this.router.url + '#page__content');
    this.activateErrorRoute();
    this.displayGlobalSystemError();
    this.hideHamburgerIcon();
  }

  loginCheckCall() {
    if (this.router.url.split("?")[0] == "/logout") {
      this.accountService.logout(true);
      this.data.isLoginIn = false;
      this.data.isMigratedUserGlobal = false;
      this.data.isCoverImageDownloadedGlobal = false;
      this.cdref.detectChanges();
    } else {
      this.accountService.setKMLI();
      this.commonService.isLoading_hide();
      this.cdref.detectChanges();
    }
  }

  platformCheckCall() {
    if (!this.isPlatformBrowser) {
      this.currentUrl = this.codec.decodeValue(this.router.url);
      let idValue;
      if (this.currentUrl != '/') {
        let params = this.currentUrl?.split('?')[1];
        let id_param = params?.split('&')[0];
        idValue = id_param?.split('=')[1];
      }
      if (idValue === LOGINPARAMS.LOGIN) {
        this.getMachineIdentifierFromServer(this.loginbuttonText);
      } else if (idValue === LOGINPARAMS.CREATEACCOUNT) {
        this.loadCreateAccountWebApp();
      }
    } else {
      this.currentUrl = this.router.url;
    }
  }

  activateErrorRoute() {
    this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe(params => {
      if (params.StatusCode) {
        this.commonService.globalSystemErrorPage$.next({ showErrorPage: true, statusCode: 500, errDetails: "SoMe Msg" });
      }
    });
  }
  displayGlobalSystemError() {
    this.commonService.globalSystemErrorPage$.pipe(takeUntil(this.destroy$)).subscribe((statusObj: any) => {
      if (statusObj.showErrorPage) {
        this.globalSystemErrorPage = true;
        this.cdref.detectChanges();
      }
    });
  }
  trackHelpLinkClick(){
    this.gtmService.advanceTracking (Settings, GTMEvents.ButtonClick, {button_name:'Help', click_text:this.data.languageJson?.login.header_link?.help_link})
  }
  resetPageLoad() {
    if (!GlobalSettings.isBrowser) {
      this.globalSystemErrorPage = false;
      this.ngOnInit()
    }
  }
  redirectToCentralizedURL(): void {
    this.isRegistration = "false";
    let platform: string;
    platform = this.constants.OIDCConstants.MeePlatform;
    let isLoadTest: boolean = false;
    let strAppURL: string = environment.siteUrl;
    if (GlobalSettings.isDesktop || GlobalSettings.isNative) {
      if (location.href != null && location.href != undefined && location.href != '') {
        strAppURL = location.href.split('://')[0] + '://' + location.href.split('://')[1].split('/')[0];
        strAppURL = strAppURL + GlobalSettings.routePath + "/index.html";
      }
    }
    this.gtmService.advanceTracking(Settings, GTMEvents.ButtonClick, {button_name:'Login', click_text:this.data.languageJson?.login?.login_btn});
    this.gtmService.eventTracking(Login, 'Login Button Clicked');
    this.accountService.redirectToCentralizedURL(platform, strAppURL, this.isRegistration, this.isSkipUserCheck, this.data.machineIdentifier, isLoadTest).pipe(takeUntil(this.destroy$)).subscribe((response) => {
      this.openServiceResponse(response.redirectionUrl);
    });
  }
  loadCreateAccountWebApp(): void {
    this.isRegistration = "true";
    let platform: string = '';
    platform = this.constants.OIDCConstants.MeePlatform;
    let isLoadTest: boolean = false;
    let strAppURL: string = '';
    this.gtmService.advanceTracking(Settings, GTMEvents.ButtonClick, {button_name:'Register', click_text:this.data.languageJson?.login?.account_create_btn})
    this.gtmService.eventTracking(Login, 'Create an account button clicked');
    this.accountService.redirectToCentralizedRegistrationURL(platform, this.isRegistration, this.isSkipUserCheck, strAppURL, this.data.machineIdentifier, isLoadTest).pipe(takeUntil(this.destroy$)).subscribe((response) => {
      this.commonService.isLoading_hide();
      if (isPlatformBrowser(this.platformId) || this.data.isInNative) {
        window.open(response.redirectionUrl, '_blank');
      } else {
        this._meta.addTag({ 'http-Equiv': 'refresh', content: '0; ' + response.redirectionUrl });
      }
    });
  }
  openServiceResponse(_responseURL: string): void {
    this.commonService.isLoading_show();
    if(GlobalSettings.isDesktop) {
      of("delay").pipe(delay(0),first()).subscribe(() => {
        this.commonService.isLoading_show();
      });
    };
    if (isPlatformBrowser(this.platformId) || this.data.isInNative) {
      window.open(_responseURL, '_self');
    }
    else {
      this._meta.addTag({ 'http-Equiv': 'refresh', content: '0; ' + _responseURL });
    }
  }

  getMachineIdentifierFromServer(strAccount: string) {
    this.commonService.isLoading_show();
    this.cookieService.delete('isnojs');
    this.accountService.getMachineIdentifier().pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      if (response) {
        this.data.machineIdentifier = response.uuid;
        if (strAccount === 'Login') {
          this.redirectToCentralizedURL();
        } else {
          this.loadCreateAccountWebApp();
        }
      }
    });
  }

  callNativeforWebview = (event: any) => {
    if (GlobalSettings.isNative) {
      let externalUrl = '';
      let elemClass = event.target.classList[0];
      if (elemClass === "helpUrls") {
        let externalUrlSuffix = "help-centre";
        externalUrl = this.constants.baseURL + externalUrlSuffix;
        this.commonService.openExternalURLCallback(externalUrl);
      }

    }
  };

  public isMobileDetector(){
    return (this.deviceDetectorService.isMobile() && (this.deviceType != getdeviceType.tablet) && GlobalSettings.isBrowser);
  }


  public dynamicRendering(flag:string){
    const isMobileDetected = this.isMobileDetector();
    if(GlobalSettings.isDesktop || GlobalSettings.isBrowser && !isMobileDetected){  
    switch (flag) {
      case 'add':
        this.classesToAdd.forEach((className:any) => this._renderer.addClass(this.bodyElement, className));
        break;
      case 'remove':
        this.classesToAdd.forEach((className:any) => this._renderer.removeClass(this.bodyElement, className));
        break;
    }
  }
  }

  public checkLoadingDone(){
    combineLatest([this.data.isLoading,this.data.isLoadingLgoin]).subscribe((_)=>{
      if(isPlatformBrowser(this.platformId)){
        this.dynamicRendering("remove")  
      }
     })
  }

  hideHamburgerIcon(): void {
    if(GlobalSettings.isBrowser && !this.currentUrl.includes('/download') ){
      let hamburgerIcon : any = document.querySelector('#hamburgerIcon')
      if(hamburgerIcon){
        hamburgerIcon.style.visibility = 'visible'
      }
    }
  }
  
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.data.loginStatus = true;
    this._renderer.removeClass(document.body, 'login-page');
    const removeElement:any = document.getElementsByClassName('optanon-alert-box-wrapper');
    if (removeElement && removeElement.length) {
     removeElement[0].remove();
    } 
    window.removeEventListener("pageshow", this.handlePageShow.bind(this));
  }
}